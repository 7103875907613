<template>
  <div @click="open">{{ displayedTitle }}</div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { ConfirmMixin } from './confirm-mixin';

@Component({
  name: 'ConfirmMenuItem',
  mixins: [ConfirmMixin],
  props: {
    header: String,
    target: {
      type: String,
      default: ''
    },
    title: String,
    hidden: Boolean
  }
})
export default class extends Vue {}
</script>
