const VideoStatuses = {
  Unknown: 'UNKNOWN',
  Uploading: 'UPLOADING',
  Awaiting: 'AWAITING',
  Inprogress: 'INPROGRESS',
  Disabled: 'DISABLED',
  Completed: 'COMPLETED',
  Ready: 'READY',
  Starting: 'STARTING',
  Deleted: 'DELETED',
  Interrupted: 'INTERRUPTED',
  NotStarted: 'NOT_STARTED',
  TooManyCameras: 'TOO_MANY_CAMERAS',
  Max: 'MAX'
};

const VideoStatusToColor = {
  unknown: '#555',
  awaiting: '#777',
  inprogress: '#fc0',
  uploading: '#bdbdbd',
  disabled: '#555',
  starting: '#fc0',
  deleted: '#c00',
  interrupted: '#c00',
  not_started: '#c00',
  too_many_cameras: '#c00',
  license_error: '#c00',
  completed: '#0c0',
  ready: '#0c0',
  max: '#555'
};

const VideoProgressStatuses = [VideoStatuses.Inprogress, VideoStatuses.Uploading];

export { VideoStatuses, VideoStatusToColor, VideoProgressStatuses };
